import { useToast } from '@chakra-ui/react'

export function useToastFeedback(successMessage: string, errorMessage: string) {
    const toast = useToast()

    return {
        onCompleted: () => {
            toast({
                status: 'success',
                duration: 3000,
                description: successMessage,
            })
        },
        onError: () => {
            toast({
                status: 'error',
                duration: 3000,
                description: errorMessage,
            })
        },
    }
}

export function useGenericToasts(
    successMessage?: string,
    errorMessage?: string
) {
    const toast = useToast()

    return {
        onSuccess: (newMessage?: string) => {
            toast({
                status: 'success',
                duration: 3000,
                description: newMessage || successMessage || 'Success!',
            })
        },
        onError: (newMessage?: string) => {
            toast({
                status: 'error',
                duration: 3000,
                description: newMessage || errorMessage || 'Error',
            })
        },
    }
}
