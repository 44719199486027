import { endOfToday, startOfToday } from 'date-fns'
import { useClinic } from '~contexts/clinic-context'
import {
    AppointmentFieldsFragment,
    ClientFieldsFragment,
    RevenueGroup,
    TimeSlotFieldsFragment,
    useGetClientsWithNegativeBalancesForClinicLazyQuery,
    useGetClinicAnalyticsLazyQuery,
    useGetTimeSlotsForClinicForDayLazyQuery,
} from '~graphql/generated/graphql'

type TodayProviderType = {
    runTodayQueries: () => void
    isTodayLoading: boolean
    todayData: TodayDataType
}

export type TodayDataType = {
    appointments: AppointmentFieldsFragment[]
    revenuesByClinician: RevenueGroup[]
    accountsReceivable: number
    clientsWithNegativeBalance: ClientFieldsFragment[]
    clientsWithNegativeBalanceCount: number
    timeSlots: TimeSlotFieldsFragment[]
    scheduleEfficiency: number
}

export const defaultTodayData: TodayDataType = {
    appointments: [],
    revenuesByClinician: [],
    accountsReceivable: 0,
    clientsWithNegativeBalance: [],
    clientsWithNegativeBalanceCount: 0,
    timeSlots: [],
    scheduleEfficiency: 0,
}

const calculateTotalOwed = (clients: ClientFieldsFragment[]) => {
    let total = 0
    clients.forEach(({ balance }) => {
        total += balance || 0
    })
    return total
}

export const useToday = (): TodayProviderType => {
    const { clinic } = useClinic()

    const [
        getAnalyticsForToday,
        { data: todayAnalyticsData, loading: todayLoading },
    ] = useGetClinicAnalyticsLazyQuery()

    const [getTimeSlots, { data: timeSlotData, loading: timeSlotLoading }] =
        useGetTimeSlotsForClinicForDayLazyQuery()

    const [getClientsWhoOwe, { data: clientsData, loading: clientsLoading }] =
        useGetClientsWithNegativeBalancesForClinicLazyQuery({
            fetchPolicy: 'no-cache',
        })

    const runTodayQueries = () => {
        const todayStart = startOfToday().getTime()
        const todayEnd = endOfToday().getTime()

        getAnalyticsForToday({
            variables: {
                clinicId: clinic?.id || '',
                fromDate: todayStart,
                toDate: todayEnd,
            },
        })

        getTimeSlots({
            variables: {
                clinicId: clinic?.id || '',
                date: todayStart,
            },
        })

        getClientsWhoOwe({ variables: { clinicId: clinic?.id || '' } })
    }

    const {
        appointments,
        clients_with_negative_balances_count,
        revenue_by_clinician,
    } = todayAnalyticsData?.clinicAnalytics || {
        appointments: [],
        revenue_by_clinician: [],
        clients_with_negative_balances_count: 0,
    }

    const clients_with_negative_balances =
        clientsData?.getClientsWithNegativeBalancesForClinic || []

    const { time_slots, schedule_efficiency } =
        timeSlotData?.getTimeSlotsForClinicForDay || {
            time_slots: [],
            schedule_efficiency: 0,
        }

    return {
        runTodayQueries,
        isTodayLoading: todayLoading || timeSlotLoading || clientsLoading,
        todayData: {
            appointments,
            revenuesByClinician: revenue_by_clinician,
            accountsReceivable: calculateTotalOwed(
                clients_with_negative_balances
            ),
            clientsWithNegativeBalance: clients_with_negative_balances,
            clientsWithNegativeBalanceCount:
                clients_with_negative_balances_count || 0,
            timeSlots: time_slots,
            scheduleEfficiency: schedule_efficiency,
        },
    }
}
