import {
    ClinicFieldsFragment,
    PracticeManagementSystem,
} from '~graphql/generated/graphql'

export const isCloudPMS = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Rhapsody:
        case PracticeManagementSystem.Provet:
        case PracticeManagementSystem.Pulse:
        case PracticeManagementSystem.Shepherd:
        case PracticeManagementSystem.Cornerstone:
        case PracticeManagementSystem.ImpromedInfinity:
        case PracticeManagementSystem.Daysmart:
            return true
    }
    return false
}

export const requiresAPIKey = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Rhapsody:
        case PracticeManagementSystem.Provet:
        case PracticeManagementSystem.Shepherd:
        case PracticeManagementSystem.Daysmart:
            return true
    }
    return false
}

export const requiresSecondAPIKey = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Shepherd:
            return true
    }

    return false
}

export const requiresUsernamePassword = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Pulse:
        case PracticeManagementSystem.Shepherd:
        case PracticeManagementSystem.Daysmart:
        case PracticeManagementSystem.EzyVet:
            return true
    }
    return false
}

export const requiresPin = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Shepherd:
            return true
    }
    return false
}

export const requiresApiClinicId = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Rhapsody:
        case PracticeManagementSystem.Provet:
        case PracticeManagementSystem.Shepherd:
        case PracticeManagementSystem.Cornerstone:
        case PracticeManagementSystem.ImpromedInfinity:
        case PracticeManagementSystem.Daysmart:
        case PracticeManagementSystem.EzyVet:
            return true
    }
    return false
}

export const requiresApiSubdomain = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Provet:
        case PracticeManagementSystem.Pulse:
        case PracticeManagementSystem.Shepherd:
        case PracticeManagementSystem.EzyVet:
            return true
    }
    return false
}

export const requiresPulseFields = (
    pms: PracticeManagementSystem | null | undefined
) => {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Pulse:
            return true
    }
    return false
}

export function requiresRoomAndClinician(
    pms: PracticeManagementSystem | null | undefined
) {
    if (!pms) return false

    switch (pms) {
        case PracticeManagementSystem.Cornerstone:
        case PracticeManagementSystem.ImpromedInfinity:
            return true
    }
    return false
}
