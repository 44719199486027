import React from 'react'
import { Button, Tooltip } from '@chakra-ui/react'
import { navigate } from 'gatsby'

import { OrganizationFieldsFragment } from '~graphql/generated/graphql'

interface Props {
    organization: OrganizationFieldsFragment
    setOrganization: (e: OrganizationFieldsFragment | undefined) => void
}

const OrganizationHeader: React.FC<Props> = ({
    organization,
    setOrganization,
}) => {
    return (
        <Tooltip label="Switch to clinic" openDelay={500} placement="left">
            <Button
                variant="subtle"
                size="xs"
                px={3}
                colorScheme="brand"
                textTransform={'uppercase'}
                onClick={() => {
                    setOrganization(undefined)
                    navigate('/user-clinics')
                }}
            >
                {organization.name}
            </Button>
        </Tooltip>
    )
}

export default OrganizationHeader
