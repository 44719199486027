import { addMinutes, endOfDay, isSameDay, startOfDay } from 'date-fns'
import { useMemo } from 'react'
import { useClinic } from '~contexts/clinic-context'
import {
    AppointmentFieldsFragment,
    AppointmentStatus,
    PatientFieldsFragment,
    PracticeManagementSystem,
    RevenueGroup,
    useGetClinicAnalyticsLazyQuery,
    useGetTimeSlotsForClinicForDayLazyQuery,
} from '~graphql/generated/graphql'
import {
    dedupeArray,
    endOfDayWithTimeZone,
    getLastOpenDayForClinic,
    startOfDayWithTimeZone,
} from '~utils/helpers'

type YesterdayProviderType = {
    yesterday: Date
    runYesterdayQueries: () => void
    isYesterdayLoading: boolean
    yesterdayData: YesterdayDataType
}

export type YesterdayDataType = {
    appointments: AppointmentFieldsFragment[]
    revenue: number
    revenueByType: RevenueGroup[]
    uniquePatients: number
    patientsWithoutReschedule: PatientFieldsFragment[]
    monthlyProductionCompleted: number
    monthlyAppointmentsScheduled: number
    monthlyTotalTransactions: number
    lastMonthAverage: number
    newClients: number
    newPatients: number
    newAppointments: number
    noShowPercentage: number
    scheduleEfficiency: number
}

export const defaultYesterdayData: YesterdayDataType = {
    appointments: [],
    revenue: 0,
    revenueByType: [],
    uniquePatients: 0,
    patientsWithoutReschedule: [],
    monthlyProductionCompleted: 0,
    monthlyAppointmentsScheduled: 0,
    monthlyTotalTransactions: 0,
    lastMonthAverage: 0,
    newClients: 0,
    newPatients: 0,
    newAppointments: 0,
    noShowPercentage: 0,
    scheduleEfficiency: 0,
}

export const useYesterday = (): YesterdayProviderType => {
    const { clinic } = useClinic()

    const yesterday = useMemo(
        () => getLastOpenDayForClinic(clinic),
        [clinic?.hours]
    )

    const [
        getAnalyticsForYesterday,
        { data: yesterdayAnalyticsData, loading: yesterdayLoading },
    ] = useGetClinicAnalyticsLazyQuery()

    const [getTimeSlots, { data: timeSlotsData, loading: timeSlotsLoading }] =
        useGetTimeSlotsForClinicForDayLazyQuery()

    const runYesterdayQueries = () => {
        const yesterdayStart = addMinutes(
            startOfDayWithTimeZone(yesterday, clinic?.time_zone),
            -1
        ).getTime()
        const yesterdayEnd = addMinutes(
            endOfDayWithTimeZone(yesterday, clinic?.time_zone),
            1
        ).getTime()

        getAnalyticsForYesterday({
            variables: {
                clinicId: clinic?.id || '',
                fromDate: yesterdayStart,
                toDate: yesterdayEnd,
            },
        })

        getTimeSlots({
            variables: {
                clinicId: clinic?.id || '',
                date: yesterday.getTime(),
            },
        })
    }

    const {
        appointments,
        last_month_average_revenue_per_appointment,
        monthly_revenue_completed,
        monthly_appointments_scheduled,
        new_appointments_count,
        new_clients_count,
        new_patients_count,
        appointments_count,
        no_show_count,
        patients_without_reschedules,
        revenue,
        transactions_count,
        revenue_by_type,
    } = yesterdayAnalyticsData?.clinicAnalytics || {
        monthly_revenue_completed: 0,
        monthly_appointments_scheduled: 0,
        last_month_average_revenue_per_appointment: 0,
        appointments: [],
        transactions_count: 0,
        revenue: 0,
        revenue_by_type: [],
        patients_without_reschedules: [],
        new_clients_count: 0,
        new_patients_count: 0,
        new_appointments_count: 0,
        appointments_count: 0,
        no_show_count: 0,
    }

    const uniquePatients = [
        ...new Set((appointments || []).map(a => a.patient?.id)),
    ].length

    const newPatients = appointments.filter(
        a =>
            a.pms_created_at &&
            a.patient?.pms_created_at &&
            isSameDay(a.pms_created_at, a.patient.pms_created_at)
    )

    return {
        yesterday,
        runYesterdayQueries,
        isYesterdayLoading: yesterdayLoading || timeSlotsLoading,
        yesterdayData: {
            monthlyProductionCompleted: monthly_revenue_completed,
            monthlyAppointmentsScheduled: monthly_appointments_scheduled,
            lastMonthAverage: last_month_average_revenue_per_appointment,
            appointments,
            monthlyTotalTransactions: transactions_count,
            revenue,
            revenueByType: revenue_by_type,
            uniquePatients,
            patientsWithoutReschedule: patients_without_reschedules,
            newClients: new_clients_count,
            newPatients: newPatients.length,
            newAppointments: new_appointments_count,
            noShowPercentage: (no_show_count / (appointments_count || 1)) * 100,
            scheduleEfficiency:
                (timeSlotsData?.getTimeSlotsForClinicForDay
                    .schedule_efficiency || 0) * 100,
        },
    }
}
