import { IconType } from 'react-icons'
import { IoPaw } from 'react-icons/io5'
import {
    BiBarChartAlt2,
    BiBell,
    BiBellPlus,
    BiClinic,
    BiCodeAlt,
    BiCoffee,
    BiCog,
    BiGroup,
    BiHomeAlt,
    BiInfoCircle,
    BiLibrary,
    BiMedal,
    BiMessageRounded,
    BiMoney,
    BiPieChartAlt2,
    BiPointer,
    BiRuler,
    BiStoreAlt,
    BiSync,
    BiStar,
    BiTime,
    BiUser,
    BiUserCircle,
    BiBuoy,
    BiCalendar,
    BiTable,
    BiBuildingHouse,
    BiGift,
    BiDetail,
    BiTerminal,
    BiDoorOpen,
    BiTrendingUp,
    BiBulb,
} from 'react-icons/bi'
import { BsGenderAmbiguous } from 'react-icons/bs'

import {
    ClinicianFieldsFragment,
    ClinicFeatureFieldsFragment,
    ResourceType,
} from '~graphql/generated/graphql'
import { useClinic } from '~contexts/clinic-context'
import { splitArrayWithFilter } from '~utils/helpers'
import { useActionItems } from '~contexts/action-item-provider'
import { AdminRoutes, UserRoutes } from '~config/routes'

export type SidebarItem = {
    label: string
    icon?: IconType
    to?: string
    badge?: number
    pages?: SidebarItem[]
    hideIf?: boolean
}

export function useSidebarContent(
    features: ClinicFeatureFieldsFragment | null | undefined
): {
    sidebarItems: SidebarItem[]
    clinicAdminItems: SidebarItem[]
    adminItems: SidebarItem[]
    enterpriseItems: SidebarItem[]
} {
    const { clinic, clinicians, unreadMessages } = useClinic()
    const { actionItems } = useActionItems()

    const usingRooms = clinic?.settings?.does_use_rooms_to_schedule
    const usingClinicians = clinic?.settings?.does_use_clinicians_to_schedule

    const {
        huddle_enabled: isUsingHuddle,
        messaging_enabled: isUsingChat,
        payments_enabled: isUsingPayments,
        scheduling_enabled: isUsingScheduling,
        reviews_enabled: isUsingReviews,
        loyalty_enabled: isUsingLoyalty,
        notifs_enabled: isUsingNotifs,
        forms_enabled: isUsingForms,
        help_enabled: isUsingHelp,
    } = features || {}

    const sidebarItems: SidebarItem[] = [
        {
            icon: BiHomeAlt,
            label: 'Home',
            to: UserRoutes.Home,
        },
        {
            icon: BiCoffee,
            label: 'Daily Huddle',
            to: UserRoutes.DailyHuddle,
            hideIf: !isUsingHuddle,
        },
        {
            icon: BiTime,
            label: 'Pending Actions',
            to: UserRoutes.PendingActions,
            badge: actionItems.length,
            hideIf: !isUsingScheduling,
        },
        {
            icon: BiMessageRounded,
            label: 'Messages',
            to: UserRoutes.Chats,
            badge: unreadMessages,
            hideIf: !isUsingChat,
        },
        {
            icon: BiLibrary,
            label: 'All Appointments',
            to: UserRoutes.AllAppointments,
            hideIf: !isUsingScheduling,
        },
        {
            icon: BiCalendar,
            label: 'Schedule',
            to: '/schedule',
            hideIf: !isUsingScheduling,
        },
    ].filter(i => !i.hideIf)

    const clinicAdminItems: SidebarItem[] = [
        {
            icon: BiPieChartAlt2,
            label: 'Analytics',
            pages: [
                {
                    icon: BiBarChartAlt2,
                    label: 'All Analytics',
                    to: UserRoutes.AllAnalytics,
                    hideIf: !isUsingHuddle,
                },
                {
                    icon: BiMedal,
                    label: 'Goals',
                    to: UserRoutes.Goals,
                    hideIf: !isUsingHuddle,
                },
            ],
        },
        {
            icon: BiPointer,
            label: 'Booking',
            pages: [
                {
                    icon: BiGroup,
                    label: 'Clinicians',
                    to: UserRoutes.Clinicians,
                    badge: clinicians.filter(clinician => !clinician.hours)
                        .length,
                    hideIf: !usingClinicians,
                },
                {
                    icon: BiDoorOpen,
                    label: 'Rooms',
                    to: UserRoutes.Rooms,
                    hideIf: !usingRooms,
                },
                {
                    icon: BiMoney,
                    label: 'Payments',
                    to: UserRoutes.Payments,
                    hideIf: !isUsingPayments,
                },
                {
                    icon: BiRuler,
                    label: 'Scheduling Rules',
                    to: UserRoutes.SchedulingRules,
                    hideIf: !isUsingScheduling,
                },
                {
                    icon: BiStoreAlt,
                    label: 'Services',
                    to: UserRoutes.Services,
                },
                {
                    icon: IoPaw,
                    label: 'Species',
                    to: UserRoutes.Species,
                },
                {
                    icon: BsGenderAmbiguous,
                    label: 'Sexes',
                    to: UserRoutes.Sexes,
                },
                {
                    icon: BiCodeAlt,
                    label: 'Widgets',
                    to: UserRoutes.Widgets,
                    hideIf: !(isUsingScheduling || isUsingChat),
                },
            ].filter(i => !i.hideIf),
        },
        {
            icon: BiBell,
            label: 'Engagement',
            pages: [
                {
                    icon: BiBellPlus,
                    label: 'Notifications',
                    to: UserRoutes.Notifications,
                    hideIf: !isUsingNotifs,
                },
                {
                    icon: BiGroup,
                    label: 'Client Segments',
                    to: UserRoutes.ClientSegments,
                    hideIf: !isUsingNotifs,
                },
                {
                    icon: BiStar,
                    label: 'Reviews',
                    to: UserRoutes.Reviews,
                    hideIf: !isUsingReviews,
                },
                {
                    icon: BiGift,
                    label: 'Loyalty',
                    to: UserRoutes.Loyalty,
                    hideIf: !isUsingLoyalty,
                },
                {
                    icon: BiDetail,
                    label: 'Forms',
                    to: UserRoutes.Forms,
                    hideIf: !isUsingForms,
                },
                {
                    icon: BiTerminal,
                    label: 'Logs',
                    to: UserRoutes.NotificationLogs,
                    hideIf: !isUsingNotifs,
                },
            ].filter(i => !i.hideIf),
        },
        {
            icon: BiClinic,
            label: 'Clinic',
            pages: [
                {
                    icon: BiUserCircle,
                    label: 'Users',
                    to: UserRoutes.ClinicUsers,
                },
                {
                    icon: BiInfoCircle,
                    label: 'Clinic Info',
                    to: UserRoutes.ClinicInfo,
                    badge: clinic?.onboarding?.has_set_hours ? 0 : 1,
                },
                {
                    icon: BiSync,
                    label: 'Data Sync',
                    to: UserRoutes.DataSync,
                },
            ],
        },
        {
            icon: BiCog,
            label: 'Settings',
            to: UserRoutes.Settings,
        },
        {
            icon: BiBuoy,
            label: 'Help',
            to: UserRoutes.Help,
            hideIf: !isUsingHelp,
        },
    ].filter((i: SidebarItem) => !i.hideIf)

    const adminItems: SidebarItem[] = [
        {
            icon: BiBuildingHouse,
            label: 'Organizations',
            to: '/admin/organizations',
        },
        {
            icon: BiClinic,
            label: 'Clinics',
            to: AdminRoutes.Clinics,
        },
        {
            icon: BiUser,
            label: 'Users',
            to: AdminRoutes.Users,
        },
    ]

    const enterpriseItems: SidebarItem[] = [
        {
            icon: BiPieChartAlt2,
            label: 'Overview',
            to: '/enterprise',
        },
        // {
        //     icon: BiTable,
        //     label: 'Reporting',
        //     to: '/enterprise/reporting',
        // },
        // {
        //     icon: BiCoffee,
        //     label: 'Daily Huddle',
        //     to: '/enterprise/huddle',
        // },
        {
            icon: BiTable,
            label: 'Reports',
            to: '/enterprise/reports',
        },
        {
            icon: BiTrendingUp,
            label: 'Trends',
            to: '/enterprise/trends',
        },
        {
            icon: BiBulb,
            label: 'Marketing',
            to: '/enterprise/marketing',
        },
        {
            icon: BiBuildingHouse,
            label: 'Organization',
            to: '/enterprise/info',
        },
    ]

    return {
        sidebarItems,
        clinicAdminItems,
        adminItems,
        enterpriseItems,
    }
}
