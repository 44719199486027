import firebase from 'gatsby-plugin-firebase'
import { setContext } from '@apollo/client/link/context'

const getToken = async () => {
    let token = await firebase.auth().currentUser?.getIdToken()

    return token
}

export const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getToken()
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }
})

export const handlePasswordChange = async (
    oldPassword: string,
    newPassword: string
): Promise<{ status: 'error' | 'success'; message: string }> => {
    let isError = false
    let message = 'Password changed successfully!'
    try {
        const user = firebase.auth().currentUser
        const cred = firebase.auth.EmailAuthProvider.credential(
            user?.email || '',
            oldPassword
        )
        await user
            ?.reauthenticateWithCredential(cred)
            .then(() => {
                user?.updatePassword(newPassword).catch(e => {
                    isError = true
                    message = `${e}`
                })
            })
            .catch(e => {
                isError = true
                message = 'Old password is not correct.'
            })
    } catch (e: any) {
        isError = true
        message = `${e}`
    }
    return { status: isError ? 'error' : 'success', message }
}
