export const customShadows = {
    soft: {
        xs: '0 0 0 1px rgba(113, 128, 150, 0.05)',
        sm: '0 1px 2px 0 rgba(113, 128, 150, 0.05)',
        base: '0 1px 3px 0 rgba(113, 128, 150, 0.1), 0 1px 2px 0 rgba(113, 128, 150, 0.06)',
        md: '0 4px 6px -1px rgba(113, 128, 150, 0.1), 0 2px 4px -1px rgba(113, 128, 150, 0.06)',
        lg: '0 10px 15px -3px rgba(113, 128, 150, 0.1), 0 4px 6px -2px rgba(113, 128, 150, 0.05)',
        xl: '0 20px 25px -5px rgba(113, 128, 150, 0.1), 0 10px 10px -5px rgba(113, 128, 150, 0.04)',
        '2xl': '0 25px 50px -12px rgba(113, 128, 150, 0.25)',
        top: '0 -10px 15px -3px rgba(113, 128, 150, 0.1), 0 4px 6px -2px rgba(113, 128, 150, 0.05)',
    },
    brand: {
        xs: '0 0 0 1px rgba(19, 216, 121, 0.05)',
        sm: '0 1px 2px 0 rgba(19, 216, 121, 0.05)',
        base: '0 1px 3px 0 rgba(19, 216, 121, 0.1), 0 1px 2px 0 rgba(19, 216, 121, 0.06)',
        md: '0 4px 6px -1px rgba(19, 216, 121, 0.1), 0 2px 4px -1px rgba(19, 216, 121, 0.06)',
        lg: '0 10px 15px -3px rgba(19, 216, 121, 0.1), 0 4px 6px -2px rgba(19, 216, 121, 0.05)',
        xl: '0 20px 25px -5px rgba(19, 216, 121, 0.1), 0 10px 10px -5px rgba(19, 216, 121, 0.04)',
        '2xl': '0 25px 50px -12px rgba(19, 216, 121, 0.25)',
        top: '0 -10px 15px -3px rgba(19, 216, 121, 0.1), 0 4px 6px -2px rgba(19, 216, 121, 0.05)',
        right: '-10px 0 15px -3px rgba(19, 216, 121, 0.1), -4px 0 6px -2px rgba(19, 216, 121, 0.05)',
    },
    blue: {
        xs: '0 0 0 1px rgba(49, 130, 206, 0.05)',
        sm: '0 1px 2px 0 rgba(49, 130, 206, 0.05)',
        base: '0 1px 3px 0 rgba(49, 130, 206, 0.1), 0 1px 2px 0 rgba(49, 130, 206, 0.06)',
        md: '0 4px 6px -1px rgba(49, 130, 206, 0.1), 0 2px 4px -1px rgba(49, 130, 206, 0.06)',
        lg: '0 10px 15px -3px rgba(49, 130, 206, 0.1), 0 4px 6px -2px rgba(49, 130, 206, 0.05)',
        xl: '0 20px 25px -5px rgba(49, 130, 206, 0.1), 0 10px 10px -5px rgba(49, 130, 206, 0.04)',
        '2xl': '0 25px 50px -12px rgba(49, 130, 206, 0.25)',
        top: '0 -4px 6px -1px rgba(49, 130, 206, 0.1), 0 -2px 4px -1px rgba(49, 130, 206, 0.06)',
        right: '20px 0 25px -5px rgba(49, 130, 206, 0.1), 10px 0 10px -5px rgba(49, 130, 206, 0.04)',
    },
    'inset-blue': {
        xs: 'inset 0 0 0 1px rgba(49, 130, 206, 0.05)',
        sm: 'inset 0 0 2px 0 rgba(49, 130, 206, 0.05)',
        base: 'inset 0 0 3px 0 rgba(49, 130, 206, 0.1), inset 0 0 2px 0 rgba(49, 130, 206, 0.06)',
        md: 'inset 0 0 6px -1px rgba(49, 130, 206, 0.1), inset 0 0 4px -1px rgba(49, 130, 206, 0.06)',
        lg: 'inset 0 0 15px -3px rgba(49, 130, 206, 0.1), inset 0 0 6px -2px rgba(49, 130, 206, 0.05)',
        xl: 'inset 0 0 25px -5px rgba(49, 130, 206, 0.1), inset 0 0 10px -5px rgba(49, 130, 206, 0.04)',
        '2xl': 'inset 0 0 50px -12px rgba(49, 130, 206, 0.25)',
    },
    'inset-soft': {
        xs: 'inset 0 0 0 1px rgba(113, 128, 150, 0.05)',
        sm: 'inset 0 0 2px 0 rgba(113, 128, 150, 0.05)',
        base: 'inset 0 0 3px 0 rgba(113, 128, 150, 0.1), inset 0 0 2px 0 rgba(113, 128, 150, 0.06)',
        md: 'inset 0 0 6px -1px rgba(113, 128, 150, 0.1), inset 0 0 4px -1px rgba(113, 128, 150, 0.06)',
        lg: 'inset 0 0 15px -3px rgba(113, 128, 150, 0.1), inset 0 0 6px -2px rgba(113, 128, 150, 0.05)',
        xl: 'inset 0 0 25px -5px rgba(113, 128, 150, 0.1), inset 0 0 10px -5px rgba(113, 128, 150, 0.04)',
        '2xl': 'inset 0 0 50px -12px rgba(113, 128, 150, 0.25)',
    },
}

export const customColors = {
    brand: {
        50: '#ECFDF5',
        75: '#D1FAE5',
        100: '#B4F8D7',
        200: '#7CF4BA',
        300: '#56F0A6',
        400: '#31ED92',
        500: '#13D879',
        600: '#10BC69',
        700: '#0B8349',
        800: '#085E34',
        900: '#032615',
    },
    purple: {
        50: '#E8D8FD',
        100: '#D0B1FB',
        200: '#B98AF9',
        300: '#A163F8',
        400: '#8A3CF6',
        500: '#6D0CF2',
        600: '#5709C3',
        700: '#45079C',
        800: '#340675',
        900: '#23044E',
    },
    pink: {
        50: '#FDD8FD',
        100: '#FBB2FB',
        200: '#F88BF8',
        300: '#F665F6',
        400: '#F43EF4',
        500: '#EA0EEB',
        600: '#C10BC1',
        700: '#9A099A',
        800: '#740774',
        900: '#4D044D',
    },
    blue: {
        25: '#f2faff',
        50: '#D8F3FD',
        100: '#B1E6FB',
        200: '#8ADAF9',
        300: '#63CEF8',
        400: '#3CC1F6',
        500: '#0CAFF2',
        600: '#0880AF',
        700: '#066488',
        800: '#054761',
        900: '#032B3A',
    },
    danger: {
        50: '#FFEDE7',
        100: '#FFE7DC',
        200: '#FFCAB9',
        300: '#FFA796',
        400: '#FF857C',
        500: '#FF5154',
        600: '#DB3B4B',
        700: '#B72844',
        800: '#93193B',
        900: '#7A0F36',
    },
    success: {
        50: '#EFFBF2',
        100: '#E1FCDC',
        200: '#BEF9B9',
        300: '#93EF95',
        400: '#74E081',
        500: '#49CC65',
        600: '#35AF5B',
        700: '#249251',
        800: '#177646',
        900: '#0E6140',
    },
}
