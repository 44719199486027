import React, { useContext, useEffect, useRef, useState } from 'react'

import {
    Box,
    Button,
    Center,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    Text,
    Heading,
    Icon,
    Image,
    Spinner,
} from '@chakra-ui/react'
import { navigate } from 'gatsby-link'
import { FiLogOut } from 'react-icons/fi'
import { FaHospital } from 'react-icons/fa'
import { useMatch, useLocation } from '@reach/router'

import Header from './header'
import Logo from '~images/logo/logo.svg'
import Sidebar, { SidebarContent } from './sidebar/sidebar'
import { UserContext } from '~config/user-context'
import SidebarTab from './sidebar/sidebar-tab'
import { useClinic } from '~contexts/clinic-context'
import TutorialModal from './tutorial-modal'
import { BiExit } from 'react-icons/bi'
import { UserRoutes } from '~config/routes'

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const [submenu, setSubmenu] = useState<string>('none')
    const { pathname } = useLocation()
    const matchAuth =
        useMatch(UserRoutes.Auth) || useMatch(UserRoutes.ForgotPassword)
    const matchClinicSelect = useMatch(UserRoutes.UserClinics)
    const matchRhapsodyWidget = useMatch(UserRoutes.RhapsodyWidget)
    const { user, userId, isLoading } = useContext(UserContext)
    const { clinic, clinicInStorage } = useClinic()

    const firstLoad = useRef(true)

    useEffect(() => {
        if (!user && !userId && !isLoading && !firstLoad.current) {
            navigate(UserRoutes.Auth)
        }
        firstLoad.current = false
    }, [user, isLoading, userId])

    useEffect(() => {
        setDrawerOpen(false)
    }, [pathname])

    if (isLoading || (!clinic && clinicInStorage))
        return (
            <Center h="100vh" w="100vw">
                <Spinner />
            </Center>
        )

    if (matchAuth || matchClinicSelect || matchRhapsodyWidget)
        return <Box>{children}</Box>

    return (
        <Box
            minH="100vh"
            transition="0.4s"
            pt={{ base: '56px', lg: '58px' }}
            pl={{
                base: '0px',
                lg: isOpen ? '276px' : submenu !== 'none' ? '128px' : '64px',
            }}
            w="100%"
        >
            <Header
                isOpen={isOpen}
                onOpenSidebar={() => setDrawerOpen(true)}
                submenu={submenu}
            />
            <Box display={{ lg: 'none' }}>
                <Drawer
                    isOpen={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                >
                    <DrawerOverlay>
                        <DrawerContent>
                            <MobileSidebar />
                        </DrawerContent>
                    </DrawerOverlay>
                </Drawer>
            </Box>
            {children}
            <TutorialModal />
            <Box
                position="fixed"
                top={0}
                bottom={0}
                left={0}
                zIndex={2}
                display={{ base: 'none', lg: 'initial' }}
            >
                <Sidebar
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    submenu={submenu}
                    setSubmenu={setSubmenu}
                />
            </Box>
        </Box>
    )
}

export default Layout

const MobileSidebar: React.FC = () => {
    const { signOut } = useContext(UserContext)
    const [submenu, setSubmenu] = useState<string>('none')

    return (
        <Flex
            h="100vh"
            flexDir="column"
            bg="gray.900"
            justifyContent="space-between"
        >
            <Box pt={4} pb={3} px={3}>
                <HStack spacing={4}>
                    <Image
                        src={Logo}
                        alt="Check Up Logo"
                        placeholder="none"
                        borderWidth={1}
                        borderColor="black"
                        h={9}
                    />
                    <Heading size="sm" fontWeight={300} color="brand.500">
                        Clinic Manager
                    </Heading>
                </HStack>
            </Box>
            <Box h="full" overflow="auto">
                <SidebarTab
                    icon={FaHospital}
                    to={UserRoutes.UserClinics}
                    label={'Switch Clinic'}
                    isOpen={true}
                    setSubmenu={setSubmenu}
                />
                <SidebarContent
                    isOpen={true}
                    submenu={submenu}
                    setSubmenu={setSubmenu}
                />
                {/* <Box h="64px" /> */}
            </Box>
            <Flex
                w="100%"
                position="relative"
                textAlign={'left'}
                px={3}
                py={3}
                align="center"
                justify="flex-start"
                cursor="pointer"
                transition="all 0.2s"
                bg={'gray.800'}
                onClick={() => signOut()}
            >
                <Flex
                    minW={8}
                    boxSize={8}
                    justify="center"
                    align="center"
                    ml={1}
                    mr={2}
                >
                    <Icon as={BiExit} boxSize="20px" color="red.400" />
                </Flex>
                <Text color="red.400">Sign Out</Text>
            </Flex>
        </Flex>
    )
}
