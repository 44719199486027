import React from 'react'
import {
    Avatar,
    Box,
    Button,
    HStack,
    Link,
    Menu,
    MenuButton,
    MenuGroup,
    MenuList,
    Text,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { UserRoutes } from '~config/routes'
import { useUserContext } from '~config/user-context'
import MenuItem from '~components/shared/menu/menu-item'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'
import { getSupabaseLink, SupabaseEntities } from '~config/constants'
import { BiCopy, BiRefresh, BiInfoCircle, BiLinkExternal } from 'react-icons/bi'

interface Props {
    clinic: ClinicFieldsFragment
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    isClinicAdmin: boolean
}

const ClinicHeader: React.FC<Props> = ({
    clinic,
    setClinic,
    isClinicAdmin,
}) => {
    const { isAdmin } = useUserContext()

    return (
        <HStack>
            <Menu placement="bottom-end">
                <MenuButton
                    as={Button}
                    variant="subtle"
                    px={3}
                    size="xs"
                    textTransform="uppercase"
                    colorScheme="blue"
                >
                    {clinic.name}
                </MenuButton>
                <MenuList pt={0}>
                    <Box px={4} py={2}>
                        <HStack spacing={4}>
                            <Avatar
                                size="sm"
                                src={clinic.avatar_url || ''}
                                name={clinic.name || ''}
                            />
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold">
                                    {clinic.name}
                                </Text>
                                {isAdmin && (
                                    <Text fontSize="xs" color="gray.500">
                                        {clinic.id}
                                    </Text>
                                )}
                            </Box>
                        </HStack>
                    </Box>
                    <MenuGroup title="Options" fontSize="xs">
                        {isClinicAdmin && (
                            <MenuItem
                                icon={BiInfoCircle}
                                onClick={() => navigate(UserRoutes.ClinicInfo)}
                            >
                                View Info
                            </MenuItem>
                        )}
                        <MenuItem
                            icon={BiRefresh}
                            onClick={() => {
                                setClinic(undefined)
                                navigate(UserRoutes.UserClinics)
                            }}
                        >
                            Switch Clinic
                        </MenuItem>
                    </MenuGroup>
                    {isAdmin && (
                        <MenuGroup title="Admin" fontSize="xs">
                            <MenuItem
                                icon={BiCopy}
                                onClick={() =>
                                    navigator.clipboard.writeText(clinic.id)
                                }
                            >
                                Copy Clinic ID
                            </MenuItem>
                            <Link
                                variant="unstyled"
                                href={getSupabaseLink(
                                    SupabaseEntities.Clinic,
                                    clinic.id
                                )}
                                isExternal
                            >
                                <MenuItem icon={BiLinkExternal}>
                                    View in Supabase
                                </MenuItem>
                            </Link>
                        </MenuGroup>
                    )}
                </MenuList>
            </Menu>
        </HStack>
    )
}

export default ClinicHeader
