import { mode } from '@chakra-ui/theme-tools'
import { defineStyleConfig, ComponentStyleConfig } from '@chakra-ui/react'

const Button: ComponentStyleConfig = defineStyleConfig({
    variants: {
        link: {
            shadow: 'none',
        },
        ghost: {
            shadow: 'none',
        },
        subtle: (props: any) => ({
            shadow: 'none',
            bg:
                props.colorMode === 'dark'
                    ? `${props.colorScheme}.700`
                    : `${props.colorScheme}.100`,
            color:
                props.colorMode === 'dark'
                    ? `${props.colorScheme}.100`
                    : `${props.colorScheme}.800`,
            _hover: {
                filter: 'brightness(0.95)',
            },
        }),
        withShadow: (props: any) => ({
            bg: `${props.colorScheme}.500`,
            color: 'white',
            shadow: `${props.colorScheme}.lg`,
            transition: 'all 0.2s',
            _hover: {
                bg: `${props.colorScheme}.600`,
                shadow: `${props.colorScheme}.xl`,
            },
        }),
    },
})

const Skeleton: ComponentStyleConfig = {
    baseStyle: {
        rounded: 'lg',
    },
}

const Link: ComponentStyleConfig = {
    baseStyle: {
        fontWeight: 600,
    },
    variants: {
        primary: {
            color: 'brand.500',
            _hover: {
                color: 'brand.400',
                textDecoration: 'underline',
            },
        },
        ghost: {
            color: 'gray.800',
            _hover: {
                color: 'gray.600',
                textDecoration: 'underline',
            },
        },
    },
    defaultProps: {
        variant: 'ghost',
    },
}

const Heading: ComponentStyleConfig = {
    variants: {
        primary: {
            color: 'brand.500',
        },
        basic: {
            fontWeight: 800,
            color: 'dark',
        },
        gradient: {
            fontWeight: 900,
            bgGradient:
                'linear-gradient(90deg, rgba(36,166,130,1) 0%, rgba(0,237,145,1) 100%)',
            bgClip: 'text',
        },
    },
    defaultProps: {
        variant: 'basic',
    },
}

const Text: ComponentStyleConfig = {
    variants: {
        sectionHeading: {
            fontWeight: 800,
            color: 'dark',
            fontSize: 'lg',
        },
    },
}

const Input: ComponentStyleConfig = {
    defaultProps: {
        borderWidth: 2,
        focusBorderColor: 'brand.500',
    },
    variants: {
        filled: props => ({
            field: {
                borderWidth: 0,
                _focusVisible: {
                    bg: mode('gray.100', 'whiteAlpha.50')(props),
                },
            },
        }),
    },
}

export const components = {
    Button,
    Skeleton,
    Link,
    Heading,
    Text,
    Input,
    Popover: {
        variants: {
            responsive: {
                popper: {
                    maxWidth: 'unset',
                    width: 'unset',
                },
                content: {
                    maxWidth: 'unset',
                    width: 'unset',
                },
            },
        },
    },
}
