export const SYNC_SERVER_URL = `${
    process.env.GATSBY_SYNC_API_URL ||
    'https://chckup-sync-server-dev.uc.r.appspot.com'
}`

export type SyncEndpointType =
    (typeof SYNC_ENDPOINTS)[keyof typeof SYNC_ENDPOINTS]

export const SYNC_ENDPOINTS = {
    APPOINTMENTS: 'sync-appointments',
    BREEDS: 'sync-breeds',
    CLIENTS: 'sync-clients',
    CLINICIANS: 'sync-clinicians',
    CLOUD_FULL_SYNC: 'cloud-full-sync',
    CLOUD_SHORT_SYNC: 'cloud-short-sync',
    DIAGNOSES: 'sync-diagnoses',
    INVOICES: 'sync-invoices',
    PATIENTS: 'sync-patients',
    PATIENT_HISTORY_ITEMS: 'sync-patient-history-items',
    PRESCRIPTIONS: 'sync-prescriptions',
    PRODUCTS: 'sync-products',
    ROOMS: 'sync-rooms',
    SPECIES: 'sync-species',
    SERVICES: 'sync-appt-types',
}
