import React, { useState, createContext, useEffect, useContext } from 'react'
import { LocalStorageKeys } from '~config/constants'
import { UserContext } from '~config/user-context'

import {
    AuthRoles,
    ClinicFieldsFragment,
    ClinicianFieldsFragment,
    ConnectInstallationFieldsFragment,
    FilterOp,
    OrganizationFieldsFragment,
    RoomFieldsFragment,
    useCountChatsQuery,
    useGetClinicAdminsByClinicQuery,
    useGetCliniciansByClinicQuery,
    useGetClinicQuery,
    useGetConnectInstallationsByClinicQuery,
    useGetOrganizationQuery,
    useGetRoomsForClinicQuery,
} from '~graphql/generated/graphql'

type ClinicProviderType = {
    clinic: ClinicFieldsFragment | undefined
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    organization: OrganizationFieldsFragment | undefined
    setOrganization: (e: OrganizationFieldsFragment | undefined) => void
    isClinicAdmin: boolean
    refetchClinic: ({ id }: { id: string }) => Promise<any>
    clinicians: ClinicianFieldsFragment[]
    rooms: RoomFieldsFragment[]
    connectInstallations: ConnectInstallationFieldsFragment[]
    clinicInStorage: boolean | undefined
    loading: boolean
    unreadMessages: number
    refreshUnreadMessages: () => Promise<any>
}

export const ClinicContext = createContext<ClinicProviderType>({
    clinic: undefined,
    clinicians: [],
    rooms: [],
    setClinic: () => {},
    organization: undefined,
    setOrganization: () => {},
    isClinicAdmin: false,
    refetchClinic: () => Promise.resolve(),
    connectInstallations: [],
    clinicInStorage: false,
    loading: true,
    unreadMessages: 0,
    refreshUnreadMessages: () => Promise.resolve(),
})

export const ClinicProvider = ({ children }: { children: React.ReactNode }) => (
    <ClinicContext.Provider value={{ ...useClinicValues() }}>
        {children}
    </ClinicContext.Provider>
)

function useClinicValues(): ClinicProviderType {
    const { userId } = useContext(UserContext)

    const [clinic, setClinic] = useState<ClinicFieldsFragment>()
    const [organization, setOrganization] =
        useState<OrganizationFieldsFragment>()
    const [isClinicAdmin, setIsClinicAdmin] = useState<boolean>(false)

    const handleSetClinic = (clinic: ClinicFieldsFragment | undefined) => {
        const organization = localStorage.getItem(
            LocalStorageKeys.selectedOrganization
        )

        if (clinic) {
            localStorage.setItem(LocalStorageKeys.selectedClinic, clinic.id)
            if (organization)
                localStorage.removeItem(LocalStorageKeys.selectedOrganization)
        } else {
            localStorage.removeItem(LocalStorageKeys.selectedClinic)
        }
        setClinic(clinic)
    }

    const handleSetOrganization = (
        organization: OrganizationFieldsFragment | undefined
    ) => {
        const clinic = localStorage.getItem(LocalStorageKeys.selectedClinic)

        if (organization) {
            localStorage.setItem(
                LocalStorageKeys.selectedOrganization,
                organization.id
            )
            if (clinic) localStorage.removeItem(LocalStorageKeys.selectedClinic)
        } else {
            localStorage.removeItem(LocalStorageKeys.selectedOrganization)
        }
        setOrganization(organization)
    }

    const [clinicInStorage, setClinicInStorage] = useState<string>()
    const [organizationInStorage, setOrganizationInStorage] = useState<string>()

    const {
        data: organizationData,
        loading: organizationLoading,
        refetch: refetchOrganization,
    } = useGetOrganizationQuery({
        variables: {
            id: organizationInStorage || '',
        },
        onError: () => {
            setOrganization(undefined)
            setOrganizationInStorage(undefined)
        },
        skip: !organizationInStorage,
    })

    const { data: clinicAdminData } = useGetClinicAdminsByClinicQuery({
        variables: {
            clinicId: clinic?.id || '',
        },
        skip: !clinic?.id,
    })

    const { data: cliniciansData } = useGetCliniciansByClinicQuery({
        variables: {
            clinicId: clinic?.id || '',
        },
        skip: !clinic?.id,
    })

    const { data: roomsData } = useGetRoomsForClinicQuery({
        variables: {
            clinicId: clinic?.id || '',
        },
        skip: !clinic?.id,
    })

    const clinicians = cliniciansData?.getCliniciansByClinic || []
    const rooms = roomsData?.getRoomsByClinic || []

    const { data: installationData } = useGetConnectInstallationsByClinicQuery({
        variables: { clinicId: clinic?.id || '' },
        skip: !clinic,
    })

    const connectInstallations =
        installationData?.getConnectInstallationsByClinic || []

    // sleep for 4 seconds

    const { data: unreadMessages, refetch: refreshUnreadMessages } =
        useCountChatsQuery({
            variables: {
                where: [
                    {
                        column: 'clinic',
                        filterOp: FilterOp.Eq,
                        value: {
                            string: clinic?.id || '',
                        },
                    },
                    {
                        column: 'clinic_unread_count',
                        filterOp: FilterOp.Gt,
                        value: {
                            float: 0,
                        },
                    },
                ],
            },
            pollInterval: 30000,
            skip: !clinic,
        })

    const unreadMessagesCount = unreadMessages?.countChats || 0

    const { data, loading, refetch } = useGetClinicQuery({
        variables: {
            id: clinicInStorage || '',
        },
        onError: () => {
            setClinic(undefined)
            setClinicInStorage(undefined)
        },
        skip: !clinicInStorage,
    })

    useEffect(() => {
        setClinic(data?.clinic as ClinicFieldsFragment)
    }, [data])

    useEffect(() => {
        if (clinic) {
            localStorage.setItem(LocalStorageKeys.selectedClinic, clinic.id)
        } else {
            setClinicInStorage(undefined)
        }
    }, [clinic])

    useEffect(() => {
        const clinicInStorage = localStorage.getItem(
            LocalStorageKeys.selectedClinic
        )
        setClinicInStorage(clinicInStorage || undefined)
    }, [])

    useEffect(() => {
        const organizationInStorage = localStorage.getItem(
            LocalStorageKeys.selectedOrganization
        )
        setOrganizationInStorage(organizationInStorage || undefined)
    }, [])

    useEffect(() => {
        setOrganization(
            organizationData?.organization as OrganizationFieldsFragment
        )
    }, [organizationData])

    useEffect(() => {
        const role = clinicAdminData?.getClinicAdminsByClinic?.find(
            ca => ca.user?.id === userId
        )?.role
        setIsClinicAdmin(role ? role === AuthRoles.ClinicManager : false)
    }, [clinicAdminData])

    return {
        clinic,
        setClinic: handleSetClinic,
        organization,
        setOrganization: handleSetOrganization,
        isClinicAdmin,
        refetchClinic: refetch,
        clinicians,
        rooms,
        connectInstallations,
        clinicInStorage: Boolean(clinicInStorage),
        loading,
        unreadMessages: unreadMessagesCount,
        refreshUnreadMessages,
    }
}

export const useClinic = () => {
    return useContext(ClinicContext)
}
