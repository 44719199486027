import React, {
    useState,
    createContext,
    useContext,
    useRef,
    useEffect,
    useMemo,
} from 'react'

import {
    AppointmentFieldsFragment,
    AppointmentStatus,
    useCheckIfServerRunningLazyQuery,
    useSearchAppointmentsQuery,
    useConfirmAppointmentMutation,
    useSendAppointmentPromptMutation,
    AppointmentPromptType,
    useMarkAppointmentAsResolvedMutation,
} from '~graphql/generated/graphql'
import { confirmAppointment, denyAppointment } from '~rest/sync.rest'
import {
    useGenericToasts,
    useToastFeedback,
} from '~utils/hooks/use-toast-feedback'
import { isCloudPMS } from '~utils/pms-helpers'
import { useClinic } from './clinic-context'

type PendingAppointmentsProviderType = {
    pendingAppointments: AppointmentFieldsFragment[]
    bookedAppointments: AppointmentFieldsFragment[]
    cancelledAppointments: AppointmentFieldsFragment[]
    isLoading: boolean
    refetch: () => Promise<any>
    confirmAppointment: (appointmentId: string) => Promise<boolean>
    denyAppointment: (
        appointmentId: string,
        reasonForDenial?: string
    ) => Promise<boolean>
    requestChange: (
        appointment: AppointmentFieldsFragment,
        reasonForDenial?: string
    ) => Promise<boolean>
}

export const PendingAppointmentsContext =
    createContext<PendingAppointmentsProviderType>({
        pendingAppointments: [],
        bookedAppointments: [],
        cancelledAppointments: [],
        isLoading: false,
        refetch: () => Promise.resolve(),
        confirmAppointment: () => Promise.resolve(false),
        denyAppointment: () => Promise.resolve(false),
        requestChange: () => Promise.resolve(false),
    })

export const PendingAppointmentsProvider = ({
    children,
}: {
    children: React.ReactNode
}) => (
    <PendingAppointmentsContext.Provider
        value={{ ...usePendingAppointmentValues() }}
    >
        {children}
    </PendingAppointmentsContext.Provider>
)

function usePendingAppointmentValues(): PendingAppointmentsProviderType {
    const { clinic } = useClinic()
    const { onSuccess, onError } = useGenericToasts()
    const { current: currentTime } = useRef(Date.now())
    const intervalId = useRef<NodeJS.Timer | null>(null)

    const [appointments, setAppointments] = useState<
        AppointmentFieldsFragment[]
    >([])
    const [confirmedAppointmentIds, setConfirmedAppointmentIds] = useState<
        string[]
    >([])

    const { data, loading, refetch } = useSearchAppointmentsQuery({
        variables: {
            searchInput: {
                clinicId: clinic?.id || '',
                is_native_origin: true,
                start_date: currentTime,
                limit: 999,
            },
        },
        pollInterval: 15000,
        skip: !clinic,
    })

    const [createAppointmentPrompt] = useSendAppointmentPromptMutation()

    const [confirmAppointmentMutation] = useConfirmAppointmentMutation()

    const [markAppointmentAsResolved] = useMarkAppointmentAsResolvedMutation()

    const dedupedAppointments = useMemo(
        () =>
            appointments.filter(
                (appt, index, self) =>
                    index ===
                    self.findIndex(
                        t => t.id === appt.id && t.start_time > currentTime
                    )
            ),
        [appointments, currentTime]
    )

    useEffect(() => {
        intervalId.current = setInterval(() => {
            setConfirmedAppointmentIds([])
        }, 90000)

        return () => {
            if (intervalId.current) {
                clearInterval(intervalId.current)
            }
        }
    }, [])

    useEffect(() => {
        if (!loading && data) {
            setAppointments(
                data.searchAppointments.filter(
                    a => !confirmedAppointmentIds.includes(a.id)
                )
            )
        }
    }, [loading, data, confirmedAppointmentIds])

    const handleRequestChange = async (
        appointment: AppointmentFieldsFragment,
        reasonForDenial?: string
    ) => {
        try {
            const { data } = await createAppointmentPrompt({
                variables: {
                    data: {
                        clinic: appointment.clinic?.id,
                        client: appointment.client?.id,
                        patient: appointment.patient?.id,
                        clinician: appointment.clinician?.id,
                        service: appointment.service?.id,
                        service_name: appointment.service_name,
                        start_time: appointment.start_time,
                        minutes: appointment.minutes,
                        reason_for_denial: reasonForDenial,
                        client_notes: appointment.client_notes,
                        clinic_notes: appointment.clinic_notes,
                        type: AppointmentPromptType.NewAppointment,
                    },
                },
            })

            if (data?.sendAppointmentPrompt) {
                await markAppointmentAsResolved({
                    variables: {
                        id: appointment.id,
                    },
                })
                await refetch()
                return true
            } else {
                return false
            }
        } catch {
            return false
        }
    }

    const handleConfirmAppointment = async (appointmentId: string) => {
        if (isCloudPMS(clinic?.pms)) {
            try {
                await confirmAppointment(clinic?.id, appointmentId)
                setConfirmedAppointmentIds([
                    ...confirmedAppointmentIds,
                    appointmentId,
                ])
                onSuccess('Appointment confirmed')
                return true
            } catch {
                onError(
                    'Could not find appointment in practice management system to confirm. Please ensure all the information is entered correctly.'
                )
                return false
            }
        } else {
            await confirmAppointmentMutation({
                variables: { id: appointmentId },
                onCompleted: () => {
                    setConfirmedAppointmentIds([
                        ...confirmedAppointmentIds,
                        appointmentId,
                    ])
                    onSuccess('Appointment confirmed')
                },
                onError: e => {
                    onError(e.message)
                },
            })

            await refetch()
            return true
        }
    }

    const handleDenyAppointment = async (
        appointmentId: string,
        reasonForDenial?: string
    ) => {
        try {
            await denyAppointment(clinic?.id, appointmentId, reasonForDenial)
            await refetch()
            return true
        } catch {
            return false
        }
    }

    return {
        pendingAppointments: dedupedAppointments.filter(
            a => a.status === AppointmentStatus.Pending
        ),
        bookedAppointments: dedupedAppointments.filter(
            a =>
                a.status === AppointmentStatus.Booked ||
                a.status === AppointmentStatus.Confirmed
        ),
        cancelledAppointments: dedupedAppointments.filter(
            a =>
                a.status === AppointmentStatus.Cancelled ||
                a.status === AppointmentStatus.Denied
        ),
        isLoading: loading,
        refetch,
        confirmAppointment: handleConfirmAppointment,
        denyAppointment: handleDenyAppointment,
        requestChange: handleRequestChange,
    }
}

export const usePendingAppointments = () => {
    return useContext(PendingAppointmentsContext)
}
