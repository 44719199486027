import { useMemo } from 'react'
import { startOfDay } from 'date-fns'
import { endOfDay } from 'date-fns/esm'
import { useClinic } from '~contexts/clinic-context'
import {
    AppointmentFieldsFragment,
    ClientFieldsFragment,
    RevenueGroup,
    TimeSlotFieldsFragment,
    useGetClientsWithNegativeBalancesForClinicLazyQuery,
    useGetClinicAnalyticsLazyQuery,
    useGetTimeSlotsForClinicForDayLazyQuery,
} from '~graphql/generated/graphql'
import { getNextOpenDayForClinic } from '~utils/helpers'

type TomorrowProviderType = {
    tomorrow: Date
    runTomorrowQueries: () => void
    isTomorrowLoading: boolean
    tomorrowData: TomorrowDataType
}

export type TomorrowDataType = {
    appointments: AppointmentFieldsFragment[]
    scheduledRevenueByClinician: RevenueGroup[]
    accountsReceivable: number
    clientsWithNegativeBalance: ClientFieldsFragment[]
    clientsWithNegativeBalanceCount: number
    timeSlots: TimeSlotFieldsFragment[]
    scheduleEfficiency: number
}

export const defaultTomorrowData: TomorrowDataType = {
    appointments: [],
    scheduledRevenueByClinician: [],
    accountsReceivable: 0,
    clientsWithNegativeBalance: [],
    clientsWithNegativeBalanceCount: 0,
    timeSlots: [],
    scheduleEfficiency: 0,
}

const calculateTotalOwed = (clients: ClientFieldsFragment[]) => {
    let total = 0
    clients.forEach(({ balance }) => {
        total += balance || 0
    })
    return total
}

export const useTomorrow = (): TomorrowProviderType => {
    const { clinic } = useClinic()
    const tomorrow = useMemo(
        () => getNextOpenDayForClinic(clinic),
        [clinic?.hours]
    )

    const [
        getAnalyticsForTomorrow,
        { data: tomorrowAnalyticsData, loading: tomorrowLoading },
    ] = useGetClinicAnalyticsLazyQuery()

    const [getTimeSlots, { data: timeSlotData, loading: timeSlotLoading }] =
        useGetTimeSlotsForClinicForDayLazyQuery()

    const [getClientsWhoOwe, { data: clientsData, loading: clientsLoading }] =
        useGetClientsWithNegativeBalancesForClinicLazyQuery({
            fetchPolicy: 'no-cache',
        })

    const runTomorrowQueries = () => {
        const tomorrowStart = startOfDay(tomorrow).getTime()
        const tomorrowEnd = endOfDay(tomorrow).getTime()

        getAnalyticsForTomorrow({
            variables: {
                clinicId: clinic?.id || '',
                fromDate: tomorrowStart,
                toDate: tomorrowEnd,
            },
        })

        getTimeSlots({
            variables: {
                clinicId: clinic?.id || '',
                date: tomorrow.getTime(),
            },
        })

        getClientsWhoOwe({ variables: { clinicId: clinic?.id || '' } })
    }

    const {
        appointments,
        clients_with_negative_balances_count,
        revenue_by_clinician,
    } = tomorrowAnalyticsData?.clinicAnalytics || {
        appointments: [],
        revenue_by_clinician: [],
        clients_with_negative_balances_count: 0,
    }

    const clients_with_negative_balances =
        clientsData?.getClientsWithNegativeBalancesForClinic || []

    const { time_slots, schedule_efficiency } =
        timeSlotData?.getTimeSlotsForClinicForDay || {
            time_slots: [],
            schedule_efficiency: 0,
        }

    return {
        tomorrow,
        runTomorrowQueries,
        isTomorrowLoading: tomorrowLoading || timeSlotLoading || clientsLoading,
        tomorrowData: {
            appointments,
            scheduledRevenueByClinician: revenue_by_clinician,
            accountsReceivable: calculateTotalOwed(
                clients_with_negative_balances
            ),
            clientsWithNegativeBalance: clients_with_negative_balances,
            clientsWithNegativeBalanceCount:
                clients_with_negative_balances_count,
            timeSlots: time_slots,
            scheduleEfficiency: schedule_efficiency,
        },
    }
}
