import React, { useState, createContext, useContext } from 'react'
import { LocalStorageKeys } from '~config/constants'
import {
    DailyHuddleFieldsFragment,
    GoalsFieldsFragment,
    useGetGoalsByClinicQuery,
} from '~graphql/generated/graphql'
import { useClinic } from '../clinic-context'
import {
    defaultTomorrowData,
    TomorrowDataType,
    useTomorrow,
} from './use-tomorrow'
import { TodayDataType, defaultTodayData, useToday } from './use-today'
import {
    defaultYesterdayData,
    useYesterday,
    YesterdayDataType,
} from './use-yesterday'

type DailyHuddleProviderType = {
    dailyHuddle: DailyHuddleFieldsFragment | undefined
    setDailyHuddle: (dailyHuddle: DailyHuddleFieldsFragment | undefined) => void
    startDailyHuddle: () => void
    stepIndex: number
    setStepIndex: (e: number) => void
    isCreating: boolean
    isYesterdayLoading: boolean
    isTodayLoading: boolean
    isTomorrowLoading: boolean
    yesterdayData: YesterdayDataType
    todayData: TodayDataType
    tomorrowData: TomorrowDataType
    goalsData: GoalsFieldsFragment[]
}

export const DailyHuddleContext = createContext<DailyHuddleProviderType>({
    dailyHuddle: undefined,
    setDailyHuddle: () => {},
    startDailyHuddle: () => {},
    stepIndex: 0,
    setStepIndex: () => {},
    isCreating: false,
    isYesterdayLoading: false,
    isTodayLoading: false,
    isTomorrowLoading: false,
    yesterdayData: defaultYesterdayData,
    todayData: defaultTodayData,
    tomorrowData: defaultTomorrowData,
    goalsData: [],
})

export const DailyHuddleProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    return (
        <DailyHuddleContext.Provider value={{ ...useDailyHuddleValues() }}>
            {children}
        </DailyHuddleContext.Provider>
    )
}

function useDailyHuddleValues(): DailyHuddleProviderType {
    const { clinic } = useClinic()

    const { runYesterdayQueries, isYesterdayLoading, yesterdayData } =
        useYesterday()
    const { runTodayQueries, isTodayLoading, todayData } = useToday()
    const { runTomorrowQueries, isTomorrowLoading, tomorrowData } =
        useTomorrow()

    const { data: goalsData } = useGetGoalsByClinicQuery({
        variables: { clinicId: clinic?.id || '' },
        skip: !clinic,
    })

    const [dailyHuddle, setDailyHuddle] = useState<DailyHuddleFieldsFragment>()
    const [stepIndex, setStepIndex] = useState<number>(-1)

    const handleSetDailyHuddle = (
        dailyHuddle: DailyHuddleFieldsFragment | undefined
    ) => {
        if (dailyHuddle) {
            localStorage.setItem(LocalStorageKeys.dailyHuddle, dailyHuddle.id)
        } else {
            localStorage.removeItem(LocalStorageKeys.dailyHuddle)
        }
        setDailyHuddle(dailyHuddle)
    }

    // const [createDailyHuddle, { loading: isCreating }] =
    //     useCreateDailyHuddleMutation({
    //         variables: {
    //             clinicId: clinic?.id || '',
    //             data: {
    //                 start_time: Date.now(),
    //             },
    //         },
    //         onCompleted: data => {
    //             setDailyHuddle(data.createDailyHuddle)
    //         },
    //         onError,
    //     })

    const startDailyHuddle = async () => {
        runYesterdayQueries()
        runTodayQueries()
        runTomorrowQueries()

        // const [{ data: dailyHuddle }] = await Promise.all([createDailyHuddle()])

        // setDailyHuddle(dailyHuddle?.createDailyHuddle)
        setStepIndex(0)
    }

    return {
        dailyHuddle,
        setDailyHuddle: handleSetDailyHuddle,
        startDailyHuddle,
        stepIndex,
        setStepIndex,
        isCreating: false, // isCreating,
        isYesterdayLoading,
        isTodayLoading,
        isTomorrowLoading,
        yesterdayData,
        todayData,
        tomorrowData,
        goalsData: goalsData?.getGoalsByClinic || [],
    }
}

export const useDailyHuddle = () => {
    return useContext(DailyHuddleContext)
}
