import React, { useContext, useEffect, useState } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import {
    AuthRoles,
    useGetUserQuery,
    UserFieldsFragment,
} from '~graphql/generated/graphql'
import { validateEmail } from '~utils/helpers'
import { LocalStorageKeys } from '~config/constants'
import { UserRoutes } from './routes'

export const UserContext = React.createContext<UserProviderType>({
    user: undefined,
    isLoading: true,
    userId: '',
    isAdmin: false,
    login: async () => ({ status: 'error', message: '' }),
    signOut: () => {},
})

type UserProviderType = {
    user: UserFieldsFragment | undefined
    isLoading: boolean
    userId: string
    isAdmin: boolean
    login: (
        email: string,
        password: string
    ) => Promise<{ status: 'error' | 'success'; message: string }>
    signOut: () => void
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => (
    <UserContext.Provider value={{ ...useUser() }}>
        {children}
    </UserContext.Provider>
)

function useUser(): UserProviderType {
    const [userId, setUserId] = useState('')
    const [{ user, isLoading }, setUser] = useState<{
        user: UserFieldsFragment | undefined
        isLoading: boolean
    }>({
        user: undefined,
        isLoading: true,
    })
    const [isAdmin, setIsAdmin] = useState(
        (user?.role &&
            [AuthRoles.SuperAdmin, AuthRoles.Admin].includes(user.role)) ||
            false
    )

    async function login(
        email: string,
        password: string
    ): Promise<{ status: 'error' | 'success'; message: string }> {
        let isError = false
        let message = 'Successfully signed in.'
        try {
            if (!validateEmail(email)) {
                isError = true
                message = 'Please enter a valid email.'
            } else if (!password) {
                isError = true
                message = 'Please enter your password.'
            } else {
                const { user: userAuth } = await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                if (userAuth) {
                    handleUserAuthRetrieved(userAuth)
                }
            }
        } catch (e: firebase.auth.Error | any) {
            isError = true
            console.error(e)
            if (
                e.code === 'auth/user-not-found' ||
                e.code === 'auth/wrong-password'
            ) {
                message = 'Email or password is incorrect. Please try again.'
            } else {
                message = `${e}`
            }
        }
        return { status: isError ? 'error' : 'success', message }
    }

    const signOut = () => {
        localStorage.removeItem(LocalStorageKeys.authToken)
        setUser({ user: undefined, isLoading: true })
        setUserId('')
        firebase
            .auth()
            .signOut()
            .then(() => {
                navigate(UserRoutes.Auth)
                setUser({ user: undefined, isLoading: false })
                setUserId('')
            })
    }

    useEffect(() => {
        if (user) {
            setIsAdmin(
                (user.role &&
                    [AuthRoles.SuperAdmin, AuthRoles.Admin].includes(
                        user.role
                    )) ||
                    false
            )
        }
    }, [user])

    const { refetch: refetchUser } = useGetUserQuery({
        onCompleted: data => {
            setUser({
                isLoading: false,
                user: data?.user || undefined,
            })
        },
        skip: true,
    })

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async userAuth => {
            handleUserAuthRetrieved(userAuth)
        })
    }, [])

    const handleUserAuthRetrieved = async (userAuth: firebase.User | null) => {
        if (userAuth) {
            setUserId(userAuth.uid)
            const token = await userAuth.getIdToken()
            localStorage.setItem(LocalStorageKeys.authToken, token)
            const { data } = await refetchUser({
                id: userAuth.uid,
            })

            setUser({
                user: data.user || undefined,
                isLoading: false,
            })
        } else {
            setUser({
                user: undefined,
                isLoading: false,
            })
        }
    }

    useEffect(() => {
        if (firebase.auth().currentUser && firebase.auth().currentUser!.uid) {
            setUserId(firebase.auth().currentUser!.uid)
        }
    }, [])

    return {
        user,
        isLoading,
        userId,
        isAdmin,
        login,
        signOut,
    }
}

export const useUserContext = () => {
    return useContext(UserContext)
}
