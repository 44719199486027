export const AdminRoutes = {
    Users: '/admin/users',
    NewUser: '/admin/users/new',
    EditUser: (id: string) => `/admin/users/${id}`,
    Clinics: '/admin/clinics',
    NewClinic: '/admin/clinics/new',
    EditClinic: (id: string) => `/admin/clinics/${id}`,
    Organizations: '/admin/organizations',
}

export const UserRoutes = {
    AllAnalytics: '/analytics',
    Goals: '/analytics/goals',
    NewGoal: '/analytics/goals/new',
    EditGoal: (id: string) => `/analytics/goals/${id}`,
    AllAppointments: '/appointments',
    AppointmentDetails: (id: string) => `/appointments/${id}`,
    SchedulingRules: '/booking/scheduling-rules',
    NewSchedulingRule: '/booking/scheduling-rules/new',
    EditSchedulingRule: (id: string) => `/booking/scheduling-rules/${id}`,
    Widgets: '/booking/widgets',
    NewWidget: '/booking/widgets/new',
    EditWidget: (id: string) => `/booking/widgets/${id}`,
    WidgetInstructions: (id: string) => `/booking/widgets/${id}/instructions`,
    WidgetPreview: (id: string, type: string) =>
        `/booking/widgets/${id}/preview/${type}`,
    Clinicians: '/booking/clinicians',
    Rooms: '/booking/rooms',
    Payments: '/booking/payments',
    Services: '/booking/services',
    Species: '/booking/species',
    Sexes: '/booking/sexes',
    Chats: '/chats',
    ChatConversation: (id: string) => `/chats/${id}`,
    ClinicInfo: '/clinic/info',
    ClinicUsers: '/clinic/users',
    DataSync: '/clinic/data-sync',
    ClientSegments: '/engagement/clients',
    ClientSegmentDetails: (id: string) => `/engagement/clients/${id}`,
    Forms: '/engagement/forms',
    EditForm: (id: string) => `/engagement/forms/edit/${id}`,
    Notifications: '/engagement/notifications',
    EditNotification: (id: string) => `/engagement/notifications/edit/${id}`,
    NotificationLogs: '/engagement/notification-logs',
    Loyalty: '/engagement/loyalty',
    Reviews: '/engagement/reviews',
    Help: '/help',
    HelpSearch: '/help/search',
    PendingActions: '/pending-actions',
    Schedule: '/schedule',
    ScheduleDay: (date: string) => `/schedule/${date}`,
    404: '/404',
    Auth: '/auth',
    ForgotPassword: '/auth/forgot-password',
    DailyHuddle: '/daily-huddle',
    Home: '/',
    Profile: '/profile',
    RhapsodyWidget: '/rhapsody-widget',
    Settings: '/settings',
    UserClinics: '/user-clinics',

    // ENTERPRISE
    Overview: '/enterprise/',
    Reports: '/enterprise/reports',
    ReportsList: '/enterprise/reports/list',
    NewReportTemplate: '/enterprise/reports/templates/new',
    ReportTemplate: (id: string) => `/enterprise/reports/templates/${id}`,
    Trends: '/enterprise/trends',
}
