import React, { useContext } from 'react'
import {
    Box,
    Flex,
    HStack,
    Button,
    Spinner,
    Progress,
    IconButton,
    useBreakpointValue,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import { FiMenu } from 'react-icons/fi'
import { useMatch } from '@reach/router'

import ClinicHeader from './clinic-header'
import { UserContext } from '~config/user-context'
import UserHeaderButton from './user-header-button'
import { ClinicContext } from '~contexts/clinic-context'
import { LoadingContext } from '~config/loading-context'
import HeaderTitle from './header-title'
import OrganizationHeader from './organization-header'
import { UserRoutes } from '~config/routes'

const Header: React.FC<{
    isOpen: boolean
    onOpenSidebar: () => void
    submenu: string
}> = ({ isOpen, onOpenSidebar, submenu }) => {
    const { user, isLoading } = useContext(UserContext)
    const { clinic, setClinic, organization, setOrganization, isClinicAdmin } =
        useContext(ClinicContext)
    const { loading } = useContext(LoadingContext)

    const showHamburger = useBreakpointValue({ base: true, lg: false })

    const matchOverview = useMatch(UserRoutes.Overview)

    const dontShowShadows = matchOverview

    return (
        <Box
            position="fixed"
            right={0}
            top={0}
            zIndex={10}
            w="100%"
            transition="all 0.4s"
            maxW={{
                base: '100%',
                lg: isOpen
                    ? 'calc(100% - 276px)'
                    : submenu !== 'none'
                    ? 'calc(100% - 128px)'
                    : 'calc(100% - 64px)',
            }}
            boxSizing="border-box"
            bg="white"
            shadow={dontShowShadows ? 'none' : 'blue.lg'}
        >
            <Flex
                position="relative"
                justify={{ base: 'space-between', lg: 'flex-end' }}
                align="center"
                w="100%"
                py={2}
                px={6}
            >
                {showHamburger ? (
                    <>
                        <HeaderTitle />
                        <IconButton
                            icon={<FiMenu />}
                            bg="gray.900"
                            rounded="full"
                            color="brand.400"
                            aria-label={'Menu Button'}
                            onClick={onOpenSidebar}
                        />
                    </>
                ) : (
                    <>
                        {isLoading ? (
                            <Spinner />
                        ) : user ? (
                            <HStack spacing={4}>
                                {clinic && (
                                    <ClinicHeader
                                        clinic={clinic}
                                        setClinic={setClinic}
                                        isClinicAdmin={isClinicAdmin}
                                    />
                                )}
                                {organization && (
                                    <OrganizationHeader
                                        organization={organization}
                                        setOrganization={setOrganization}
                                    />
                                )}
                                <UserHeaderButton
                                    user={user}
                                    setClinic={setClinic}
                                />
                            </HStack>
                        ) : (
                            <Link to={UserRoutes.Auth}>
                                <Button>Sign in</Button>
                            </Link>
                        )}
                    </>
                )}
            </Flex>
            <Progress
                h="1px"
                colorScheme="brand"
                size="sm"
                isIndeterminate={loading}
            />
        </Box>
    )
}

export default Header
