import {
    Box,
    Text,
    Flex,
    Icon,
    Spacer,
    Center,
    Tooltip,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import React from 'react'
import { useLocation } from '@reach/router'

import { IconType } from 'react-icons'
import { UserRoutes } from '~config/routes'

interface TabProps {
    icon?: IconType
    to?: string
    label: string
    badge?: number
    isOpen: boolean
    isSubPage?: boolean
    setSubmenu: (value: React.SetStateAction<string>) => void
}

const SidebarTab: React.FC<TabProps> = ({
    icon,
    to,
    label,
    badge,
    isOpen,
    isSubPage,
    setSubmenu,
}) => {
    const { pathname } = useLocation()
    const isActive = getIsActive(to || '/', pathname)

    return (
        <Box cursor="pointer" onClick={() => !isSubPage && setSubmenu('none')}>
            <Link to={to || UserRoutes.Home}>
                <Tooltip isDisabled={isOpen} label={label} placement="right">
                    <Flex
                        w="100%"
                        position="relative"
                        textAlign={'left'}
                        px={3}
                        py={2}
                        align="center"
                        cursor="pointer"
                        // _hover={{ bgColor: isSubPage ? 'gray.100' : 'gray.50' }}
                        transition="0.2s"
                        pl={isSubPage && isOpen ? 6 : 2}
                        borderLeftWidth={4}
                        borderLeftColor={
                            isActive && !isSubPage ? 'brand.500' : 'transparent'
                        }
                        bg={isSubPage && isOpen ? 'gray.800' : 'initial'}
                    >
                        <Box
                            opacity={isActive && !isSubPage ? 1 : 0}
                            position="absolute"
                            w={16}
                            left={0}
                            top={0}
                            bottom={0}
                            bgGradient="linear(to-br, rgba(19, 216, 121, 0.2), rgba(19, 216, 121, 0.05), rgba(19, 216, 121, 0), rgba(19, 216, 121, 0), rgba(19, 216, 121, 0))"
                        />
                        <Flex
                            // bg={isActive ? 'brand.500' : 'gray.200'}
                            ml={isOpen ? 1 : 0}
                            mr={isOpen ? 4 : 0}
                            minW={8}
                            boxSize={8}
                            borderRadius="xl"
                            justify="center"
                            align="center"
                            transition="0.2s"
                            position="relative"
                        >
                            <Icon
                                as={icon}
                                boxSize="20px"
                                color={isActive ? 'brand.500' : 'gray.400'}
                            />
                            {!!badge && (
                                <Center
                                    position="absolute"
                                    bg="brand.500"
                                    rounded="full"
                                    boxSize="16px"
                                    top={'-3px'}
                                    right={'-3px'}
                                >
                                    <Text
                                        fontSize="10px"
                                        fontWeight="semibold"
                                        color="white"
                                        mt={'1px'}
                                    >
                                        {badge > 9 ? '9+' : badge}
                                    </Text>
                                </Center>
                            )}
                        </Flex>
                        <Text
                            noOfLines={1}
                            opacity={isOpen ? 1 : 0}
                            color={isActive ? 'white' : 'gray.400'}
                            fontWeight={isActive ? 'bold' : 'normal'}
                            transition="0.2s"
                            fontSize="15px"
                        >
                            {label}
                        </Text>
                    </Flex>
                </Tooltip>
            </Link>
        </Box>
    )
}

export default SidebarTab

function getIsActive(to: string, pathname: string) {
    if (to.includes('enterprise')) {
        const enterprisePath = pathname.split('/')[2]
        return to === '/enterprise'
            ? pathname === '/enterprise'
            : enterprisePath === to.split('/')[2]
    }

    if (to === '/daily-huddle') {
        return pathname.split('/')[1] === 'daily-huddle'
    }

    return to === '/' ? pathname === to : pathname.includes(to)
}
